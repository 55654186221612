import React, { useState, useEffect, useContext, useRef } from 'react';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';
import LinePayment from 'pages/common/BotWallet/LinePayment';
import StatInfo from 'pages/BotDetails/StatInfo';
import BotCard from 'pages/BotDetails/BotCard';
import TransactionInfo from 'pages/BotDetails/TransactionInfo';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import AcceleratorBar from './AcceleratorBar';
import { useParams } from 'react-router-dom';
import AppContext from 'context/Context';
import ComingSoon from 'components/pages/ComingSoon';
import { BotDetails as textLang, virtualAlert } from 'staticData/languages';
import timer from 'assets/img/animated-icons/clock.json';
import MessagePopUp from 'pages/common/modals/messagePopUp';
import BotState from './BotState';
import WizardForm from './wizard/WizardLayout';
import { LinePaymentButtonMap as buttonMap } from 'staticData/common';
import { cleanCache } from 'services/cache';
import { calculateCoinsValue } from 'services/coins/functions';
import { useHistory } from 'react-router-dom';
import { currencyMap, asQuoteCoins, asSideCoins } from 'services/coins/common';

const index = () => {
  const {
    config: { lang, currency },
    coinValues,
    coinPerc24h
  } = useContext(AppContext);
  const { botName } = useParams();
  const routerHistory = useHistory();
  if (!botName) routerHistory.push('/');
  // const [botTransactions, setBotTransactions] = useState([]);
  const [loaded, setLoaded] = useState();
  const [wallet, setWallet] = useState({});
  const [dynamicCoins, setDynamicCoins] = useState(coinValues.current);
  const [dynamic24hPerc, setDynamic24hPerc] = useState(coinPerc24h.current);
  const [dynamicBotMap, setDynamicBotMap] = useState({});
  const [loadedLineChart, setLoadedLineChart] = useState(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [popUps, setPopUps] = useState({});
  const [popUpKey, setPopUpKey] = useState(0);
  const [events, setEvents] = useState(null);
  const [pendingEvents, setPendingEvents] = useState(null);
  const [wizardBotDetails, setWizardBotDetails] = useState({});
  const [triggerLoadCheckpoint, setTriggerLoadCheckpoint] = useState({
    counter: 0
  });
  var intervalLastStat;
  var checkEventsInterval = useRef();
  const useCacheInWallet = useRef(true);
  const [updateCoinValue, setUpdateCoinValue] = useState(true);
  const [dynamicLoaded, setDynamicLoaded] = useState(false);
  const [generalWallet, setGeneralWallet] = useState();
  const isScheduled = useRef(false);
  const [bot, setBot] = useState({});

  const botInfo = async (useCache = true) => {
    var params = { name: botName };
    let resp = await endpointInterface(
      lang,
      'backend',
      'botSearch',
      'get',
      true,
      params,
      useCache
    );
    var botInfo = {};
    if (resp.validResponse) {
      botInfo = resp.data;
      setWizardBotDetails({
        ...wizardBotDetails,
        start: formatBotDetails(botInfo)
      });
    }
    if (Object.keys(botInfo) === 0) routerHistory.push('/');
    return botInfo;
  };

  const getPopUps = async botID => {
    let popUpsResponse = await endpointInterface(
      lang,
      'backend',
      'getPopUps',
      'get',
      true
    );
    if (popUpsResponse.validResponse) {
      setPopUps(popUpsResponse.data);
      setPopUpKey(botID.toString());
      if (Object.keys(popUpsResponse.data).includes(botID.toString())) {
        setMessageModalShow(true);
      }
    }
  };

  const enableEventHistory = (botId = bot.id, localWallet = wallet) => {
    let check = true;
    if (
      localWallet &&
      localWallet[String(botId)] &&
      localWallet[String(botId)]['1d_timepoints']
    ) {
      const keys = Object.keys(
        localWallet[String(botId)]['1d_timepoints']
      ).sort();

      const checkKey = keys[keys.length - 2];
      const checkDate = new Date(checkKey);
      const timestamps = events.map(item => new Date(item.ts));

      // Check if any timestamp is greater than the check date
      if (timestamps.some(timestamp => timestamp > checkDate)) check = false;
    } else check = false;
    return check;
  };

  const formatBotDetails = bot => {
    let startingCoins = {};
    let startingPrices = { prices: {} };
    if (bot.initial_wallet) {
      Object.keys(bot.initial_wallet.assets).forEach(coin => {
        const currencyValue =
          bot.initial_wallet?.assets?.[coin]?.eqv?.[currencyMap[currency]] || 0;
        const quantity = bot.initial_wallet?.assets?.[coin]?.amount;
        if (currencyValue > 0) {
          startingCoins[coin] = quantity;
          startingPrices.prices[coin] = currencyValue / quantity;
        }
      });
    }
    return { startDate: bot.created_at, startingCoins, startingPrices };
  };

  const processLiveData = (walletObj = wallet) => {
    var botLocalMap = {};
    const aggregatedWallet = {};

    Object.entries(walletObj).map(([id, data]) => {
      let wallet = {};
      let assets = [];
      let botInitialAmount = 0;
      let sharpe = null;
      let coinSharpe = null;

      botInitialAmount =
        data.bot_details.initial_eqv[currencyMap[currency]] || 0;
      wallet = data.last_stat.wallet;
      assets = data.bot_details.assets;
      sharpe = data.indicators.sharpe;
      coinSharpe = data.indicators.coin_sharpe;
      // get data for otal wallet
      for (let [currency, amount] of Object.entries(wallet)) {
        if (aggregatedWallet[currency]) {
          aggregatedWallet[currency] += amount;
        } else {
          aggregatedWallet[currency] = amount;
        }
      }

      botLocalMap[id] = {
        initialAmount: botInitialAmount,
        wallet: wallet,
        assets: assets,
        sharpe: sharpe,
        coinSharpe: coinSharpe
      };
      let localWalletValue = calculateCoinsValue(wallet, coinValues.current);
      setGeneralWallet(localWalletValue);
    });

    if (!dynamicBotMap || updateCoinValue) {
      setDynamicBotMap(botLocalMap);
    }
  };

  const calcEndPerformance = (ts, botID) => {
    const data = wallet[String(botID)];
    const timestamp = new Date(ts);
    // Find the first value in the data object that contains the given timestamp
    let filteredValue;
    if (ts) {
      let foundValue = null;
      for (let key in buttonMap) {
        const timepointsKey = buttonMap[key];
        if (data[timepointsKey]) {
          const timepoints = Object.keys(data[timepointsKey]).sort();
          const start = new Date(timepoints[0]);
          const end = new Date(timepoints[timepoints.length - 1]);
          if (timestamp >= start && timestamp <= end) {
            foundValue = data[timepointsKey];
            break;
          }
        }
      }
      // If no value was found
      if (!foundValue) return [-1, -1];

      // Filter the found value to contain only the keys from the given timestamp onwards
      filteredValue = Object.keys(foundValue)
        .sort()
        .filter(key => new Date(key) >= timestamp)
        .reduce((obj, key) => {
          obj[key] = foundValue[key];
          return obj;
        }, {});
    } else filteredValue = data.all_timepoints;
    // Ordina le chiavi
    const sortedKeys = Object.keys(filteredValue).sort();

    // if empty object use first and last bot value
    if (Object.keys(filteredValue).length === 0) {
      let localGain =
        data.last_stat.eqv[currencyMap[currency]] -
        data.bot_details.initial_eqv[currencyMap[currency]];
      let localPercentageGain =
        (localGain / data.bot_details.initial_eqv[currencyMap[currency]]) * 100;
      return [localGain, localPercentageGain];
    }
    // Calcola la percentuale di differenza
    const firstUsdValue = filteredValue[sortedKeys[0]][currencyMap[currency]];
    const lastUsdValue =
      filteredValue[sortedKeys[sortedKeys.length - 1]][currencyMap[currency]];
    const percentDifference =
      ((lastUsdValue - firstUsdValue) / firstUsdValue) * 100;
    return [lastUsdValue - firstUsdValue, percentDifference];
  };
  const handleAddedLiquidity = () => {
    setLoaded(false);
  };

  const getCurrentAndPreviousMinuteIsoStrings = () => {
    const now = new Date();
    const oneMinuteAgo = new Date(now);

    // Set current time to 00:00:00 UTC for both
    now.setUTCHours(0, 0, 0, 0);
    oneMinuteAgo.setUTCHours(0, 0, 0, 0);

    // Subtract one minute from the previous time
    oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1);

    // Convert to ISO string and remove milliseconds
    const formatToIsoString = date =>
      date.toISOString().split('.')[0] + '+00:00';

    // Return array with one minute ago first, then now
    return [formatToIsoString(oneMinuteAgo), formatToIsoString(now)];
  };

  const getBotWalletStats = async botID => {
    var params = { bot_id: botID };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getWalletBot',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      let data = walletStatsResponse.data;
      if (
        data?.all_timepoints &&
        Object.keys(data?.all_timepoints).length == 0
      ) {
        walletStatsResponse.data.all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[0]
        ] = { [currencyMap[currency]]: 0 };
        walletStatsResponse.data.all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[1]
        ] = { [currencyMap[currency]]: 0 };
        setUpdateCoinValue(false);
      }
      return data;
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const handleWalletUpdate = async botInfo => {
    let localWallet = await getBotWalletStats(botInfo.id);
    // If there is no data setLoadedLineChart only for tab enable
    let checkLineChart;
    if (
      !(
        localWallet &&
        Object.keys(localWallet).length > 0 &&
        Object.keys(localWallet[botInfo.id]['all_timepoints']).length > 0
      )
    )
      checkLineChart = true;
    else checkLineChart = false;
    if (checkLineChart !== loadedLineChart) setLoadedLineChart(checkLineChart);
    setWallet(localWallet);
    setEvents(localWallet?.[String(botInfo.id)]?.snapshots || []);
    let localPendingEvents =
      localWallet?.[String(botInfo.id)]?.pending_events || [];
    setPendingEvents(localPendingEvents);
    // set interval to check if pending events are executed
    if (localPendingEvents != null && localPendingEvents.length !== 0) {
      checkEventsInterval.current = setInterval(async () => {
        let intervalWallet = await getBotWalletStats(
          botInfo.id,
          useCacheInWallet.current
        );
        setEvents(intervalWallet[botInfo.id]?.snapshots || []);
        setPendingEvents(intervalWallet[botInfo.id]?.pending_events || null);
      }, 5000);
    }
    processLiveData(localWallet);
    setDynamicLoaded(true);
  };

  const filterAndSortCoins = coins => {
    // del sidecoins
    let filteredCoins = coins.filter(coin => !asSideCoins.includes(coin));

    // order
    filteredCoins.sort((a, b) => {
      if (asQuoteCoins.includes(a) && !asQuoteCoins.includes(b)) {
        return 1;
      } else if (!asQuoteCoins.includes(a) && asQuoteCoins.includes(b)) {
        return -1;
      } else {
        return 0;
      }
    });

    // Controlla la lunghezza dell'array risultante
    if (filteredCoins.length > 2) {
      return null;
    } else {
      return filteredCoins.join('');
    }
  };

  // Use only during mounting of component and if user add liquidity -> [addedLiquidity] at the end of useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        let localBot = await botInfo();
        // Chek if bot is initally scheduled
        isScheduled.current =
          Boolean(localBot.last_stat) &&
          Object.keys(localBot.last_stat).length > 0;
        if (isScheduled.current) {
          // get coin from global
          if (coinValues.current && coinPerc24h.current) {
            setDynamicCoins(coinValues.current);
            setDynamic24hPerc(coinPerc24h.current);
          }
          // get wallet and set events
          handleWalletUpdate(localBot);
          getPopUps(localBot.id);
        } else {
          intervalLastStat = setInterval(async () => {
            let intervalBot = await botInfo(false);
            // Chek if bot is initally scheduled
            let intervalScheduled =
              Boolean(intervalBot.last_stat) &&
              Object.keys(intervalBot.last_stat) > 0;
            if (intervalScheduled) {
              isScheduled.current = intervalScheduled;
              clearInterval(intervalLastStat);
              setLoaded(false);
              return;
            }
          }, 5000);
        }
        setBot(localBot);
      } catch (error) {
        console.error(error.message);
      }
      setLoaded(true);
    };
    if (!loaded) fetchData();
  }, [loaded]);

  useEffect(async () => {
    var intervalBinanceData = setInterval(async () => {
      if (coinValues.current && coinPerc24h.current) {
        setDynamicCoins(coinValues.current);
        setDynamic24hPerc(coinPerc24h.current);
      }
    }, 500);

    return () => {
      clearInterval(intervalBinanceData);
      clearInterval(intervalLastStat);
    };
  }, []);

  // use to clean pendingEvents Interval
  useEffect(async () => {
    if (pendingEvents?.length === 0) {
      clearInterval(checkEventsInterval.current);
      await cleanCache(0);
    }
  }, [pendingEvents]);

  return (
    <>
      {loaded && (
        <MessagePopUp
          messageModalShow={messageModalShow}
          setMessageModalShow={setMessageModalShow}
          data={
            Object.keys(popUps).includes(popUpKey)
              ? popUps[popUpKey].content
              : {}
          }
          popUpId={
            Object.keys(popUps).includes(popUpKey) ? popUps[popUpKey].id : 0
          }
        />
      )}
      <>
        {loaded && bot?.status?.toUpperCase() === 'ERROR' && (
          <IconAlert key="danger" variant="danger" className="ms-2">
            {bot.status_info[lang]}
          </IconAlert>
        )}
        <Row className="mb-sm-3 gy-3">
          <Col md={5} lg={4} className="mb-2">
            <Row xs={9} className="mb-1">
              <Col>
                <BotCard bot={bot} parentLoaded={loaded} />
              </Col>
            </Row>
            {loaded && isScheduled.current && (
              <>
                <Row className="d-none d-sm-block">
                  <BotState
                    bot={bot}
                    setBot={setBot}
                    setAddedLiquidity={handleAddedLiquidity}
                    parentLoaded={loaded}
                  />
                </Row>
              </>
            )}
          </Col>
          <Col md={7} lg={8} className="mb-sm-2">
            {/* If not scheduled show timer and text */}
            {loaded && !isScheduled.current ? (
              <ComingSoon
                media={timer}
                imgCol={5}
                useImg={false}
                badge={{
                  show: true,
                  text: textLang.loading[lang],
                  colorClass: 'secondary'
                }}
                button={{
                  show: true,
                  text: textLang.dashboardLink,
                  link: '/dashboard'
                }}
                text={textLang.preparation[lang]}
              />
            ) : (
              <LinePayment
                allWallet={wallet}
                dynamicBotMap={dynamicBotMap}
                generalWallet={loaded ? generalWallet : 0}
                h100={true}
                loaded={loadedLineChart}
                dynamicLoaded={dynamicLoaded}
                setLoaded={setLoadedLineChart}
                parentLoaded={loaded && dynamicLoaded}
                singleBotName={loaded ? bot.name : null}
                singleBotID={loaded ? bot.id : null}
                triggerLoadCheckpoint={triggerLoadCheckpoint}
                setTriggerLoadCheckpoint={setTriggerLoadCheckpoint}
                singleBotBenchmark={
                  loaded
                    ? filterAndSortCoins(Object.keys(bot.last_stat.wallet))
                    : null
                }
              />
            )}
          </Col>
        </Row>
        {loaded && isScheduled.current && (
          <>
            {/* only mobile */}
            <Row className="d-sm-none">
              <BotState
                bot={bot}
                setBot={setBot}
                setAddedLiquidity={handleAddedLiquidity}
                parentLoaded={loaded}
              />
            </Row>
            {/* END only mobile */}

            <Row className="mb-2 gy-3">
              <Col className="mb-2">
                {events !== null &&
                Object.keys(wallet).length > 0 &&
                Object.keys(wizardBotDetails).length > 0 &&
                loaded &&
                dynamicLoaded ? (
                  <WizardForm
                    events={events}
                    pendingEvents={pendingEvents}
                    triggerLoadCheckpoint={triggerLoadCheckpoint}
                    setTriggerLoadCheckpoint={setTriggerLoadCheckpoint}
                    enabled={enableEventHistory()}
                    botDetails={{
                      ...wizardBotDetails,
                      end: {
                        indicators: wallet[String(bot.id)].indicators || {
                          sharpe: null,
                          coin_sharpe: null
                        },
                        performance: calcEndPerformance(
                          events.length > 0
                            ? events[events.length - 1].ts
                            : null,
                          bot.id
                        )[1]
                      }
                    }}
                  />
                ) : (
                  !dynamicLoaded && (
                    <Card
                      style={{ padding: '10% 0' }}
                      className="d-flex align-items-center"
                    >
                      <Spinner />
                    </Card>
                  )
                )}
              </Col>
            </Row>
            <Row className="mb-1 gy-3">
              <Col md={12} xl={12} xxl={5} className="mb-2">
                <AcceleratorBar
                  lastStat={loaded ? bot.last_stat : {}}
                  dynamicCoins={dynamicCoins}
                  parentLoaded={loaded}
                />
              </Col>
              <Col md={12} lg={7} xl={12} xxl={7} className="mb-2">
                {Object.keys(dynamicCoins).length &&
                  Object.keys(dynamicBotMap).length && (
                    <StatInfo
                      bot={bot}
                      events={events}
                      parentLoaded={loaded}
                      dynamicBot={dynamicBotMap[bot.id]}
                      dynamicCoins={dynamicCoins}
                      dynamic24hPerc={dynamic24hPerc}
                    />
                  )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={12}>
                <TransactionInfo botID={bot.id} parentLoaded={loaded} />
              </Col>
            </Row>
            {bot.virtual && (
              <Alert variant="secondary">
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: virtualAlert.text[lang]
                  }}
                ></p>
              </Alert>
            )}
          </>
        )}
      </>
    </>
  );
};

export default index;
