import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  InputGroup,
  Form,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import 'assets/scss/custom/botSettings.scss';
import { BotCardLanguage as textLang } from 'staticData/languages';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { botGeneralInfo } from 'staticData/common';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cleanCache } from 'services/cache';
import SoftBadge from 'components/common/SoftBadge';
import { botStatusLang } from 'staticData/languages';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { currencyMap } from 'services/coins/common';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';

const BotCard = ({ bot, parentLoaded }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);
  const routerHistory = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [newBotName, setNewBotName] = useState(bot.name);
  const [showChangeBotName, setShowChangeBotName] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);

  const statusColor = status => {
    if (status.toUpperCase() === 'ACTIVE') return 'success';
    else if (status.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const changeName = async () => {
    setShowFeedback(false);
    let form = new FormData();
    form.append('id', bot.id);
    form.append('new_name', newBotName);
    let setNewNameResponse = await endpointInterface(
      lang,
      'backend',
      'changeName',
      'post',
      true,
      form
    );
    if (setNewNameResponse.validResponse) {
      await cleanCache(0);
      // Get the URL of the page "/botdetails"
      var url = window.location.href;
      // Create a new URL object
      var newUrl = new URL(url);
      // Update the page with the new URL
      routerHistory.push(
        '/botdetails/' + newBotName + '/?' + newUrl.searchParams.toString()
      );
      setShowChangeBotName(false);
    } else {
      setFeedback(setNewNameResponse.responseMessage);
      setShowFeedback(true);
    }
  };

  const changeBotName = async () => {
    if (newBotName !== bot.name) await changeName();
    else setShowChangeBotName(false);
  };

  useEffect(() => {
    try {
      bot.typeName = Object.entries(botGeneralInfo).find(
        ([, value]) => value.id === bot.type
      )?.[0];
      if (parentLoaded && !loaded) {
        setNewBotName(bot.name);
        setLoaded(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [parentLoaded]);

  if (!parentLoaded || !loaded)
    return (
      <Card className="p-0" style={{ height: '375px' }}>
        <Card.Body>
          <div style={{ paddingTop: '50%' }}>
            <LoadingSpinner subText="" />
          </div>
        </Card.Body>
      </Card>
    );

  return (
    <Card className="h-100 p-0">
      <Flex className="justify-content-center">
        <LottieBotLazy
          type={bot.typeName}
          loop={true}
          style={{ width: '60%' }}
        />
      </Flex>
      <Card.Body>
        {!showChangeBotName ? (
          <Flex>
            <Card.Title as="h3">{newBotName}</Card.Title>
            <OverlayTrigger
              key="right"
              placement="right"
              overlay={<Tooltip id="changeName">{textLang.edit[lang]}</Tooltip>}
            >
              <div className="ps-3">
                <FontAwesomeIcon
                  className="pointerCursor"
                  icon={'edit'}
                  onClick={() => setShowChangeBotName(true)}
                />
              </div>
            </OverlayTrigger>
          </Flex>
        ) : (
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              required
              value={newBotName}
              name="botName"
              isInvalid={showFeedback}
              onChange={e => setNewBotName(e.target.value)}
            />
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip id="cancel">{textLang.cancel[lang]}</Tooltip>}
            >
              <InputGroup.Text
                className="pointerCursor"
                onClick={() => setShowChangeBotName(false)}
              >
                <FontAwesomeIcon size="md" icon={'ban'} />
              </InputGroup.Text>
            </OverlayTrigger>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip id="confirm">{textLang.confirm[lang]}</Tooltip>}
            >
              <InputGroup.Text
                className="pointerCursor"
                onClick={changeBotName}
              >
                <FontAwesomeIcon size="md" icon={'check'} />
              </InputGroup.Text>
            </OverlayTrigger>
            <Form.Control.Feedback type="invalid">
              {feedback}
            </Form.Control.Feedback>
          </InputGroup>
        )}
        <div>
          <small>{botGeneralInfo[bot.typeName]?.name || ''}</small>
          {bot?.status && (
            <SoftBadge
              style={{ width: 'auto' }}
              pill
              bg={statusColor(bot.status)}
              className="ms-2"
            >
              {botStatusLang[bot.status][lang]}
            </SoftBadge>
          )}
        </div>
        <div>
          <small>{`${textLang.initialAmount[lang]}: ${Number(
            bot?.initial_wallet?.eqv?.[currencyMap[currency]] || 0
          ).toFixed(2)}$`}</small>
        </div>
        {bot.created_at && (
          <small className="text-muted">
            {textLang.CreationDate[lang] +
              ' ' +
              new Date(bot.created_at).toLocaleDateString('en-GB')}
          </small>
        )}
      </Card.Body>
    </Card>
  );
};

BotCard.propTypes = {
  bot: PropTypes.object.isRequired,
  parentLoaded: PropTypes.bool
};

export default BotCard;
