import React, { useRef, useContext } from 'react';
import AppContext from 'context/Context';
import { binanceConvertGuide as textLang } from 'staticData/binanceConvertGuide';
import bg2 from 'assets/img/icons/spot-illustrations/corner-binance.png';
import Background from 'components/common/Background';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const Convert = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const sectionRefs = textLang.sections.map(() => useRef(null));

  const textSize = 'small';
  const lastUpdate = '03/05/2024';

  return (
    <>
      <Row className="ms-0 me-0 mb-3">
        <Card className="m-0 p-0">
          <Card.Header>
            <Background
              image={bg2}
              className="bg-card"
              style={{
                backgroundPosition: 'right',
                zIndex: '-1'
              }}
            />
            <h4 className="text-800 mb-2">{textLang.title[lang]}</h4>
            <h6
              className="text-800 mb-2"
              dangerouslySetInnerHTML={{
                __html: textLang.titleDescription[lang]
              }}
            ></h6>
          </Card.Header>
        </Card>
      </Row>
      <Row className="g-0">
        <Col>
          {textLang.sections.map((section, index) => (
            <>
              {section.header && (
                <Card className="mx-0 mt-4 mb-3 p-0">
                  <Card.Header>
                    <h4
                      className="text-800 mb-2"
                      dangerouslySetInnerHTML={{
                        __html: section.header[lang]
                      }}
                    ></h4>
                  </Card.Header>
                </Card>
              )}
              <Card
                key={index}
                className="mb-3"
                id={section.shortName[lang]}
                ref={sectionRefs[index]}
              >
                <Card.Header className="bg-light">
                  <Flex>
                    <h5 className="me-3">{index + 1}</h5>
                    <h6
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: section.name[lang]
                      }}
                    ></h6>
                  </Flex>
                </Card.Header>
                {section.description[lang] && (
                  <Card.Body>
                    <p
                      className={'mb-0 ' + textSize}
                      dangerouslySetInnerHTML={{
                        __html: section.description[lang]
                      }}
                    ></p>
                  </Card.Body>
                )}
              </Card>
            </>
          ))}
          {textLang.sectionsMobile.map((section, index) => (
            <>
              {section.header && (
                <Card className="mx-0 mt-4 mb-3 p-0">
                  <Card.Header>
                    <h4
                      className="text-800 mb-2"
                      dangerouslySetInnerHTML={{
                        __html: section.header[lang]
                      }}
                    ></h4>
                  </Card.Header>
                </Card>
              )}
              <Card
                key={index}
                className="mb-3"
                id={section.shortName[lang]}
                ref={sectionRefs[index]}
              >
                <Card.Header className="bg-light">
                  <Flex>
                    <h5 className="me-3">{index + 1}</h5>
                    <h6
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: section.name[lang]
                      }}
                    ></h6>
                  </Flex>
                </Card.Header>
                {section.description[lang] && (
                  <Card.Body>
                    <p
                      className={'mb-0 ' + textSize}
                      dangerouslySetInnerHTML={{
                        __html: section.description[lang]
                      }}
                    ></p>
                  </Card.Body>
                )}
              </Card>
            </>
          ))}
          <p className={'mb-2 ps-3 ' + textSize}>
            {textLang.lastUpdate[lang] + lastUpdate}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Convert;
