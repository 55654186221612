import React, { useContext, useState, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../components/utilities/LoadingSpinner';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from './AdvanceTableFooter';
import SoftBadge from 'components/common/SoftBadge';
import { TransactionInfoBotDetails as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { coinsData, currencyMap } from 'services/coins/common';
import { walletImgRender } from 'services/coins/components';

const TransactionInfo = ({ botID, parentLoaded }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);
  const [orders, setOrders] = useState();
  const [orderPage, setOrderPage] = useState(0);
  const [maxOrders, setMaxOrders] = useState();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const itemsInPage = 8;

  const getBotOrders = async (updatePage = false) => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'botOrders',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        bot_id: botID
      },
      queryParams: {
        page_index: updatePage ? orderPage + 1 : orderPage,
        page_size: itemsInPage * 3
      }
    });
    if (resp.validResponse) {
      let newOrders = resp.data.orders;
      if (updatePage) {
        setOrders(orders.concat(newOrders));
        setOrderPage(orderPage + 1);
      } else {
        setMaxOrders(resp.data.count);
        setOrders(newOrders);
      }
    } else setOrders([]);
  };

  const formatDate = dateString => {
    // Parsing the string with Date
    const date = new Date(dateString);

    // Formatting as year-month-day hour:minutes
    // Note: the month in JavaScript is zero-indexed, so 1 is added
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const dateFormatted = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
    return dateFormatted;
  };

  const columns = [
    {
      accessor: 'date',
      Header: textLang.date[lang],
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { closing_ts } = rowData.row.original;
        return <p>{formatDate(closing_ts)}</p>;
      }
    },
    {
      accessor: 'order',
      Header: textLang.order[lang],
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { side } = rowData.row.original;
        return (
          <SoftBadge bg={side == 'BUY' ? 'success' : 'danger'}>
            {side}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'quantity',
      Header: textLang.amount[lang],
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { executed_base_qty, coin } = rowData.row.original;
        return (
          <p>
            {executed_base_qty.toFixed(
              coinsData[coin.asset]?.decimalPrecision || 8
            )}
          </p>
        );
      }
    },
    {
      accessor: 'price',
      Header: textLang.price[lang] + ' ($)',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { eqv } = rowData.row.original;
        return <p>{eqv[currencyMap[currency]].toFixed(2)}</p>;
      }
    },
    {
      accessor: 'volume',
      Header: 'Volume ($)',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { eqv, executed_base_qty } = rowData.row.original;
        return (
          <p>{(eqv[currencyMap[currency]] / executed_base_qty).toFixed(2)}</p>
        );
      }
    },
    {
      accessor: 'coin',
      Header: 'Coin',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { coin } = rowData.row.original;
        return walletImgRender(
          [coin.asset, coin.quote],
          'justify-content-center'
        );
      }
    }
    // {
    //   accessor: 'fee',
    //   Header: textLang.fee[lang],
    //   headerProps: {
    //     className: 'text-center'
    //   },
    //   cellProps: {
    //     className: 'text-center'
    //   }
    // }
  ];

  useEffect(() => {
    try {
      if (parentLoaded && botID != null) {
        // if (transactionList.length === 0) {
        //   setTListIsFull(false);
        // }
        getBotOrders();
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [parentLoaded]);

  return (
    <Card className="h-100">
      <Card.Body className="p-0">
        <SimpleBarReact>
          <AdvanceTableWrapper
            columns={columns}
            data={orders ? orders : []}
            pagination
            perPage={itemsInPage}
          >
            {!parentLoaded || orders == null ? (
              <div style={{ paddingTop: '10%', paddingBottom: '10%' }}>
                <LoadingSpinner subText=""></LoadingSpinner>
              </div>
            ) : (
              <>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: false,
                    striped: false,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                {maxOrders === 0 ? (
                  <Row className="m-4">
                    <h5 className="text-center">{textLang.noTrans[lang]}</h5>
                  </Row>
                ) : (
                  ''
                )}
                <div className="my-3 mx-3">
                  {maxOrders > itemsInPage ? (
                    <AdvanceTableFooter
                      rowCount={maxOrders}
                      orders={orders ? orders : []}
                      getBotOrders={getBotOrders}
                      loadingOrders={loadingOrders}
                      setLoadingOrders={setLoadingOrders}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  ) : null}
                </div>
              </>
            )}
          </AdvanceTableWrapper>
        </SimpleBarReact>
      </Card.Body>
    </Card>
  );
};

TransactionInfo.propTypes = {
  botID: PropTypes.number.isRequired,
  parentLoaded: PropTypes.bool
};

export default TransactionInfo;
