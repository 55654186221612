import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import HalfDoughnutChart from './HalfDoughnutChart';
import CountUp from 'react-countup';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { AcceleratorBar as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import 'assets/scss/custom/acceleratorBar.scss';
import { asQuoteCoins, asSideCoins } from 'services/coins/common';
import { coinsData } from 'services/coins/common';

const AcceleratorBar = ({ lastStat, dynamicCoins, parentLoaded }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const calculatePercentages = (sortedCoins, wallet = lastStat.wallet) => {
    let totalValue = 0;
    let valuesInDollars = {};

    // Calcola i valori in dollari e il valore totale del wallet
    for (let coin in wallet) {
      if (Object.prototype.hasOwnProperty.call(wallet, coin)) {
        valuesInDollars[coin] = wallet[coin] * dynamicCoins[coin];
        totalValue += valuesInDollars[coin];
      }
    }

    let percentages = [];
    let orderedPercentages = [];

    // Calcola le percentuali per ogni coin
    for (let coin in valuesInDollars) {
      percentages[coin] = (valuesInDollars[coin] / totalValue) * 100;
    }

    // Ordina le percentuali secondo sortedCoins
    let percentageInvested = 0;
    sortedCoins.forEach(coin => {
      orderedPercentages.push(percentages[coin].toFixed(2));
      if (!asQuoteCoins.includes(coin)) percentageInvested += percentages[coin];
    });

    return {
      totalArray: orderedPercentages,
      invested: Number(percentageInvested.toFixed(2))
    };
  };

  // Filter all wallet
  const allCoins = parentLoaded ? Object.keys(lastStat.wallet) : [];
  const remainingCoins = allCoins.filter(
    coin => !asQuoteCoins.includes(coin) && !asSideCoins.includes(coin)
  );
  const sideCoins = allCoins.filter(coin => asSideCoins.includes(coin));
  const quoteCoins = allCoins.filter(coin => asQuoteCoins.includes(coin));

  // order coins remaining, side, quote
  const sortedCoins = [...remainingCoins, ...sideCoins, ...quoteCoins];

  const donutData = {
    labels: sortedCoins,
    datasets: [
      {
        data: parentLoaded ? calculatePercentages(sortedCoins).totalArray : [],
        backgroundColor: parentLoaded
          ? sortedCoins.map(coin => coinsData[coin].color)
          : [],
        borderColor: parentLoaded
          ? sortedCoins.map(coin => coinsData[coin].color)
          : []
      }
    ]
  };

  return (
    <>
      <Row className="h-100">
        <Col xs={12}>
          <Card
            className={
              'h-100 ' + (parentLoaded ? '' : 'justify-content-center')
            }
          >
            {parentLoaded && (
              <Card.Header className="title pb-0">
                <h4 className="mb-0">{textLang.invest[lang]}</h4>
              </Card.Header>
            )}
            {parentLoaded ? (
              <Card.Body className="pt-0 mt-0">
                <Row className="align-items-end h-100 mb-n1 pb-0">
                  <Col xs={6} xxl={8} className="coin-list pe-0 pe-lg-3">
                    {sortedCoins.map(coin => (
                      <Row className="mt-2 mb-2 g-0" key={coin}>
                        <Col xs={7}>
                          <h6 className="mb-0 text-700">
                            <font color={coinsData[coin].color}>
                              <img
                                className="me-2"
                                src={coinsData[coin].img}
                                alt=""
                                width="20"
                              />
                              {coinsData[coin].name + ' (' + coin + '): '}
                            </font>
                          </h6>
                        </Col>
                        <Col xs={5}>
                          <h6 className="mb-0 text-800">
                            {currency +
                              (lastStat.wallet[coin] > 0
                                ? dynamicCoins[coin] * lastStat.wallet[coin]
                                : 0
                              ).toFixed(2)}
                          </h6>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col xs={6} xxl={4}>
                    <div
                      className="mx-auto text-center pb-3"
                      style={{ maxWidth: '10rem' }}
                    >
                      <HalfDoughnutChart data={donutData} />
                      <CountUp
                        start={0}
                        end={calculatePercentages(sortedCoins).invested}
                        duration={2.75}
                        className="d-inline-block mb-0 mt-n6 fs-1 fw-medium font-sans-serif"
                        suffix="%"
                        separator="."
                        decimal=","
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            ) : (
              <div style={{ padding: '10% 0' }}>
                <LoadingSpinner subText=""></LoadingSpinner>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

AcceleratorBar.propTypes = {
  lastStat: PropTypes.object,
  dynamicCoins: PropTypes.object,
  parentLoaded: PropTypes.bool
};

export default AcceleratorBar;
