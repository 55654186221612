import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import AppContext from 'context/Context';
import { SaasCard as textLang } from 'staticData/languages';
import InfoPopover from 'components/utilities/InfoPopover';
import Flex from 'components/common/Flex';
import { sharpeComingSoon } from 'staticData/common';

const SaasCard = ({
  botGain,
  invested,
  sharpe,
  events = null,
  parentLoaded = true
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const sharpeNotAvString = textLang.notAvailable[lang];
  const [showSharpe, setShowSharpe] = useState(false);

  useEffect(() => {
    if (sharpe !== null && sharpe > 0) setShowSharpe(true);
  }, [parentLoaded]);

  return (
    <Card className="h-100">
      <Card.Body>
        <Row>
          <Col
            xs={6}
            className="flex-between-center"
            style={{
              borderRight: '0.1px solid var(--falcon-body-color)'
            }}
          >
            <Row xs="auto">
              <Col className="d-md-flex d-lg-block flex-between-center">
                <h6 className="mb-md-0 mt-lg-1 d-flex">
                  {textLang.title[lang]}
                  {events?.length > 0 && (
                    <InfoPopover Text={textLang.profitInfo[lang]} />
                  )}
                </h6>
              </Col>
            </Row>
            {!parentLoaded ? (
              <Flex>
                <Spinner />
              </Flex>
            ) : (
              <Row xs="auto">
                <h4 className="fs-3 fw-normal text-700">
                  {(Number(botGain) < 0 ? '-' : '') +
                    '$' +
                    Math.abs(botGain).toFixed(2)}
                </h4>
              </Row>
            )}
          </Col>
          <Col xs={6} className="flex-between-center">
            <Row xs="auto" className="ms-sm-3">
              <Col className="d-md-flex d-lg-block flex-between-center">
                <h6 className="mb-md-0 mt-lg-1 d-flex">
                  {events?.length > 0 ? (
                    <>
                      {textLang.invest[lang]}
                      <InfoPopover Text={textLang.investInfo[lang]} />
                    </>
                  ) : (
                    <>
                      Agent Sharpe Ratio
                      <InfoPopover Text={textLang.sharpeGenericInfo[lang]} />
                    </>
                  )}
                </h6>
              </Col>
            </Row>
            {!parentLoaded ? (
              <Flex className="ms-sm-3">
                <Spinner />
              </Flex>
            ) : (
              <Row xs="auto" className="ms-sm-3">
                {sharpeComingSoon ? (
                  <span className="justify-content-center">
                    <SoftBadge pill bg={'info'}>
                      coming soon
                    </SoftBadge>
                  </span>
                ) : events?.length > 0 ? ( // Show start amount if there are some events
                  <Flex>
                    <h4 className="fs-3 fw-normal text-700">
                      ${invested.toFixed(2)}
                    </h4>
                  </Flex>
                ) : (
                  <Flex>
                    <h3
                      style={{
                        opacity: showSharpe ? '' : '.3'
                      }}
                      className="fs-3 text-info"
                    >
                      {showSharpe ? sharpe : sharpeNotAvString}
                    </h3>
                    {!showSharpe && sharpe === null && (
                      <span className="pt-1">
                        <InfoPopover
                          Text={textLang.notAvailable1WeekPopOver[lang]}
                        />
                      </span>
                    )}
                  </Flex>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SaasCard.propTypes = {
  botGain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sharpe: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  events: PropTypes.array,
  parentLoaded: PropTypes.bool
};

export default SaasCard;
