import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Col, Row, Modal, ButtonGroup, ToggleButton } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import warningLight from '../../../assets/img/animated-icons/warning-light.json';
import Lottie from 'lottie-react';
import { ChangeBotStatus as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const ChangeBotStatusModal = ({ modalShow, setModalShow, bot, setStatus }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [radioSellAll, setRadioSellAll] = useState('hold');

  const handleChangeStatus = () => {
    setStatus(
      bot.status.toUpperCase() === 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
      radioSellAll === 'sell' ? true : false
    );
    setModalShow(false);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={warningLight}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4 className="text-center font-weight-bold">{textLang.title[lang]}</h4>
        <p className="text-center">
          {bot.status.toUpperCase() === 'ACTIVE'
            ? textLang.textIfActive[lang] +
              (!bot.virtual ? textLang.textIfActiveReal[lang] : '')
            : textLang.textIfPaused[lang]}
          <br />
          {bot.status.toUpperCase() === 'ACTIVE' && !bot.virtual
            ? textLang.textIfActive1[lang]
            : textLang.continue[lang]}
        </p>
        {bot.status.toUpperCase() === 'ACTIVE' && !bot.virtual && (
          <>
            <Row className="justify-content-center">
              <Col className="justify-content-center d-flex">
                <ButtonGroup>
                  <ToggleButton
                    id={`radio-0`}
                    key={'0'}
                    type="radio"
                    variant={'outline-primary'}
                    name="radio"
                    value={'hold'}
                    checked={radioSellAll === 'hold'}
                    onChange={e => setRadioSellAll(e.currentTarget.value)}
                  >
                    {textLang.hold[lang]}
                  </ToggleButton>
                  <ToggleButton
                    id={`radio-1`}
                    key={'1'}
                    type="radio"
                    variant={'outline-primary'}
                    name="radio"
                    value={'sell'}
                    checked={radioSellAll === 'sell'}
                    onChange={e => setRadioSellAll(e.currentTarget.value)}
                  >
                    {textLang.sell[lang]}
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            </Row>
            <p className="text-center">{textLang.continue[lang]}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
          <IconButton
            onClick={() => setModalShow(false)}
            variant="outline-secondary"
            className="me-2 mb-1 "
            icon="arrow-left"
            iconAlign="left"
            transform="shrink-3"
          >
            {textLang.goBack[lang]}
          </IconButton>
        </Flex>
        <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
          <IconButton
            onClick={() => handleChangeStatus()}
            variant={
              bot.status.toUpperCase() === 'ACTIVE'
                ? 'outline-warning'
                : 'outline-success'
            }
            className="me-2 mb-1 "
            icon={
              bot.status.toUpperCase() === 'ACTIVE'
                ? 'pause-circle'
                : 'play-circle'
            }
            iconAlign="left"
            transform="shrink-3"
          >
            {textLang.yes[lang]}
          </IconButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

ChangeBotStatusModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired,
  bot: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
};

export default ChangeBotStatusModal;
