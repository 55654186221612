import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import binanceImg from 'assets/img/hodlie/exchange/binance.png';
import BnbImg from 'assets/img/hodlie/coin/BNB.png';
import { BnbDiscountPage as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="align-items-center">
            <Col lg={12} className="d-flex justify-content-center m-3">
              <img src={binanceImg} style={{ height: '30px' }}></img>
              <h3
                className="text-center font-weight-bold ms-2 me-2"
                style={{ color: '#FFAF00' }}
              >
                {textLang.titleCard[lang]}
              </h3>
              <img src={BnbImg} style={{ height: '30px' }}></img>
            </Col>
          </Row>
          <Row className="mt-2 align-items-center">
            {textLang.texts.map((text, index) => (
              <p
                className="text-center"
                key={index}
                dangerouslySetInnerHTML={{
                  __html: text[lang]
                }}
              ></p>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default index;
