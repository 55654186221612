import React, { useRef, useContext } from 'react';
import AppContext from 'context/Context';
import { BinanceGuide as textLang } from 'staticData/binanceGuide';
import bg2 from 'assets/img/icons/spot-illustrations/corner-binance.png';
import Background from 'components/common/Background';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const sectionRefs = textLang.sections.map(() => useRef(null));

  const textSize = 'small';
  const lastUpdate = '21/08/2023';

  return (
    <>
      <Row className="ms-0 me-0 mb-3">
        <Card className="m-0 p-0">
          <Card.Header>
            <Background
              image={bg2}
              className="bg-card z-index-1"
              style={{
                backgroundPosition: 'right',
                zIndex: '-1'
              }}
            />
            <h4 className="text-800 mb-2">{textLang.title[lang]}</h4>
            <h6 className="text-800 mb-2">{textLang.titleDescription[lang]}</h6>
            <p
              className="mb-0"
              style={{ fontSize: '11px', lineHeight: '1.2' }}
              dangerouslySetInnerHTML={{
                __html: textLang.subtitle[lang]
              }}
            ></p>
          </Card.Header>
        </Card>
      </Row>
      <Row className="g-0">
        <Col>
          {textLang.sections.map((section, index) => (
            <Card
              key={index}
              className="mb-3"
              id={section.shortName[lang]}
              ref={sectionRefs[index]}
            >
              <Card.Header className="bg-light">
                <Flex>
                  <h5 className="me-3">{index + 1}</h5>
                  <h6
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: section.name[lang]
                    }}
                  ></h6>
                </Flex>
              </Card.Header>
              {(section.media || section.description) && (
                <Card.Body>
                  {section.description && (
                    <p
                      className={textSize}
                      dangerouslySetInnerHTML={{
                        __html: section.description[lang]
                      }}
                    />
                  )}
                  {section.media && (
                    <img
                      src={section.media}
                      style={{ width: '-webkit-fill-available' }}
                    />
                  )}
                </Card.Body>
              )}
            </Card>
          ))}
          <p className={'mb-2 ps-3 ' + textSize}>
            {textLang.lastUpdate[lang] + lastUpdate}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default index;
