import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import SaasCard from './SaasCard';
import CoinStats from 'pages/common/CoinStats';
import InfoPopover from 'components/utilities/InfoPopover';
import { getCleanBigNumber } from 'staticData/common';
import AppContext from 'context/Context';
import { SaasCard as textLang } from 'staticData/languages';
import { calcFinalPerformance } from 'staticData/common';
import { calculateCoinsValue } from 'services/coins/functions';
import { currencyMap } from 'services/coins/common';
import { asQuoteCoins, asSideCoins } from 'services/coins/common';

const StatInfo = ({
  bot,
  events,
  parentLoaded,
  dynamicBot = null,
  dynamicCoins,
  dynamic24hPerc
}) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const [volume, setVolume] = useState(null);
  const startValue = dynamicBot.initialAmount;
  const endValue = calculateCoinsValue(bot.last_stat.wallet, dynamicCoins);
  const botRevenues = calcFinalPerformance(
    startValue,
    endValue,
    events,
    currencyMap[currency]
  );
  const botGain = botRevenues[0];
  // const botGainPercentage = botRevenues[1];
  const invested = botRevenues[2];
  const asset =
    parentLoaded && dynamicBot
      ? dynamicBot.assets.filter(
          elemento =>
            !asQuoteCoins.includes(elemento) && !asSideCoins.includes(elemento)
        )[0]
      : [];

  const getBotVolume = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'botVolume',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      showError: false
    });
    if (resp.validResponse) {
      setVolume(resp.data[currencyMap[currency]]);
    }
  };

  useEffect(async () => {
    if (!volume && parentLoaded) getBotVolume();
  }, [parentLoaded]);

  return (
    <>
      <Row>
        <Col xs={12} className="mb-3">
          {dynamicBot ? (
            <SaasCard
              invested={invested}
              botGain={botGain}
              sharpe={dynamicBot.sharpe}
              events={events}
              parentLoaded={parentLoaded}
            />
          ) : (
            <Card
              style={{ padding: '5% 0' }}
              className="d-flex align-items-center"
            >
              <Spinner />
            </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Card className=" mb-3 mb-sm-3 mb-md-0">
            <Card.Body>
              <Row className="flex-between-center g-0">
                <Col xs={6} className="d-lg-block flex-between-center">
                  <h6 className="mb-2 text-900 d-flex">
                    Volume
                    <InfoPopover Text={textLang.volumeInfo[lang]} />
                  </h6>
                  {parentLoaded && volume !== null ? (
                    <h4 className="fs-3 fw-normal text-700 mb-1">
                      ${volume ? getCleanBigNumber(volume.toFixed(2)) : 0}
                    </h4>
                  ) : (
                    <div style={{ paddingTop: '10%' }}>
                      <Spinner />
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          {parentLoaded && dynamicBot ? (
            <CoinStats
              dynamicCoins={dynamicCoins}
              dynamic24hPerc={dynamic24hPerc}
              singleCoin={asset}
            />
          ) : (
            <Card
              style={{ padding: '5% 0' }}
              className="d-flex align-items-center"
            >
              <Spinner />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

StatInfo.propTypes = {
  bot: PropTypes.object,
  events: PropTypes.object,
  parentLoaded: PropTypes.bool,
  dynamicBot: PropTypes.object,
  dynamicCoins: PropTypes.object,
  dynamic24hPerc: PropTypes.object
};

export default StatInfo;
