import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { coinsData } from 'services/coins/common';
import AppContext from 'context/Context';
import classNames from 'classnames';
import 'assets/scss/custom/wizardCustom.scss';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import { breakpoints } from 'helpers/utils';
import { WizardLayout as textLang } from 'staticData/languages';
import { sharedState } from 'staticData/common';

const WizardLayout = ({
  events = [],
  pendingEvents = [],
  triggerLoadCheckpoint,
  setTriggerLoadCheckpoint,
  enabled = false,
  botDetails
}) => {
  const { lang } = sharedState.config;

  // mapp pendingEvents to get the same as events
  const mappedPendingEvents = pendingEvents.map(pe => ({
    event_arguments: pe.arguments,
    event_metadata: pe.metadata,
    event_type: 'pending',
    id: pe.id,
    ts: pe.open_ts
  }));
  // concat with events
  const combinedEvents = events.concat(mappedPendingEvents);
  // sort by ts
  combinedEvents.sort((a, b) => new Date(a.ts) - new Date(b.ts));

  const goToCheckpoint = (index, ts) => {
    if (triggerLoadCheckpoint && triggerLoadCheckpoint.id !== index) {
      let localTriggerLoadCheckpoint = { ...triggerLoadCheckpoint };
      localTriggerLoadCheckpoint.counter += 1;
      localTriggerLoadCheckpoint.ts = ts;
      localTriggerLoadCheckpoint.id = index;
      setTriggerLoadCheckpoint(localTriggerLoadCheckpoint);
    }
  };

  const startEvent = {
    id: 'start',
    ts: botDetails.start.startDate,
    event_arguments: botDetails.start.startingCoins,
    event_metadata: botDetails.start.startingPrices,
    event_type: 'start'
  };

  const endEvent = {
    id: 'end',
    ts: combinedEvents[combinedEvents.length - 1]?.ts || null,
    tsText: { 'en-EN': 'Progress...', 'it-IT': 'In corso...' },
    event_type: 'end',
    indicators: pendingEvents.length === 0 ? botDetails.end.indicators : null,
    performance: pendingEvents.length === 0 ? botDetails.end.performance : null
  };

  return (
    <Card
      as={Form}
      noValidate
      className="theme-wizard"
      style={{
        width: '-webkit-fill-available',
        boxShadow: 'unset'
      }}
    >
      <Card.Header className="title pb-0">
        <h4 className="mb-0">{textLang.history[lang]}</h4>
      </Card.Header>
      <SimpleBarReact style={{ overflowX: 'auto' }}>
        <Nav className="mt-5" style={{ flexWrap: 'nowrap' }}>
          {/* Add first */}
          <NavItem
            index={-1}
            step={
              triggerLoadCheckpoint?.id === 'end'
                ? combinedEvents.length
                : triggerLoadCheckpoint?.id
                ? combinedEvents.findIndex(
                    obj => obj.id === triggerLoadCheckpoint.id
                  )
                : -1
            }
            event={startEvent}
            totalSteps={combinedEvents.length + 1}
            goToCheckpoint={goToCheckpoint}
          />
          {combinedEvents.map((event, index) => (
            <NavItem
              key={index}
              index={index}
              enabled={
                index === combinedEvents.length - 1
                  ? enabled && pendingEvents.length === 0
                  : true
              }
              step={
                triggerLoadCheckpoint?.id === 'end'
                  ? combinedEvents.length
                  : triggerLoadCheckpoint?.id
                  ? combinedEvents.findIndex(
                      obj => obj.id === triggerLoadCheckpoint.id
                    )
                  : -1
              }
              event={event}
              totalSteps={combinedEvents.length + 1}
              goToCheckpoint={goToCheckpoint}
            />
          ))}
          {/* Add last */}
          <NavItem
            index={combinedEvents.length}
            enabled={enabled && pendingEvents.length === 0}
            step={
              triggerLoadCheckpoint?.id === 'end'
                ? combinedEvents.length
                : triggerLoadCheckpoint?.id
                ? combinedEvents.findIndex(
                    obj => obj.id === triggerLoadCheckpoint.id
                  )
                : -1
            }
            event={endEvent}
            totalSteps={combinedEvents.length + 1}
            goToCheckpoint={goToCheckpoint}
          />
        </Nav>
      </SimpleBarReact>
    </Card>
  );
};

const NavItem = ({
  index,
  step,
  event = null,
  totalSteps,
  goToCheckpoint,
  enabled = true
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const iconMap = {
    start: 'robot',
    end: 'play-circle',
    change_allocated: 'plus-circle',
    pending: 'clock'
  };

  const getColorAndCaret = num => {
    if (Number(num) < 0) return ['text-danger', 'caret-down'];
    if (Number(num) > 0) return ['text-success', 'caret-up'];
    if (Number(num) === 0) return ['text-secondary', 'minus'];
  };

  const formatDate = dateString => {
    const date = new Date(dateString);

    const month = new Intl.DateTimeFormat(lang, { month: 'short' }).format(
      date
    );
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();

    const formattedDate = `${day} ${month} ${year} <span class='d-none d-sm-inline-flex'>h${hour}</span>`;
    return formattedDate;
  };

  const printSharpe = sharpeRatio => {
    const notAvailableString = 'Agent Sharpe: ' + textLang.notAvailable[lang];
    if (sharpeRatio === null) return notAvailableString;
    else if (sharpeRatio > 0) {
      return `Agent Sharpe: <span class='text-info'>${sharpeRatio.toFixed(
        2
      )}</span>`;
    } else return notAvailableString;
  };

  const indicators = (
    <span className="d-grid" style={{ justifyItems: 'center' }}>
      <Flex
        className={'fs--1' + (event.event_type === 'pending' ? ' pt-md-2' : '')}
      >
        {event.event_type === 'pending'
          ? textLang.executing[lang]
          : enabled && event.performance != null && event.performance !== 0
          ? textLang.profit[lang] + ':'
          : ''}
        {enabled && event.performance != null && event.performance !== 0 && (
          <span
            className={'d-block ms-1 ' + getColorAndCaret(event.performance)[0]}
          >
            <FontAwesomeIcon
              icon={getColorAndCaret(event.performance)[1]}
              className="me-1"
            />
            {Math.abs(Number(event.performance.toFixed(2)))}%
          </span>
        )}
      </Flex>
      {enabled && event.performance != null && (
        <span
          className="d-block mt-1 fs--1"
          dangerouslySetInnerHTML={{
            __html: printSharpe(
              event.indicators.sharpe,
              event.indicators?.coin_sharpe
            )
          }}
        />
      )}
    </span>
  );

  return (
    <Nav.Item
      style={{
        position: 'relative',
        minWidth: window.innerWidth > breakpoints['sm'] ? '30%' : '45%'
      }}
    >
      {index !== -1 && (
        <div
          className="d-none d-md-block"
          style={{
            position: 'absolute',
            left: 0,
            top: '-50%',
            transform: 'translateY(50%)'
          }}
        >
          {indicators}
        </div>
      )}
      <Nav.Link
        className={classNames('mx-2', {
          active: step === index || totalSteps <= 1
        })}
        style={{ cursor: 'default' }}
      >
        <span className="nav-item-circle-parent-custom nav-item-circle-parent">
          <span
            className={classNames('nav-item-circle', {
              pointerCursor:
                totalSteps > 1 && enabled && event.event_type !== 'pending'
            })}
            onClick={() =>
              totalSteps > 1 &&
              enabled &&
              event.event_type !== 'pending' &&
              goToCheckpoint(event.id, event.ts)
            }
          >
            <FontAwesomeIcon icon={iconMap[event.event_type]} />
          </span>
        </span>
        <span style={{ color: 'var(--falcon-600)' }} className="pb-2">
          {(event.ts || event.tsText) && (
            <span className="d-block mt-1">
              {!event.tsText && (
                <FontAwesomeIcon icon={'calendar-alt'} className="me-1" />
              )}
              <small
                className="text-muted"
                dangerouslySetInnerHTML={{
                  __html:
                    index !== totalSteps - 1
                      ? formatDate(event.ts)
                      : event.tsText[lang]
                }}
              />
            </span>
          )}
          {event?.event_arguments &&
            Object.entries(event.event_arguments).map(
              ([coin, value]) =>
                value > 0 && (
                  <div
                    key={coin}
                    className="justify-content-center mb-1"
                    style={{ display: 'ruby-text' }}
                  >
                    <img
                      src={coinsData[coin].img}
                      alt={coin}
                      height="15"
                      className="me-1"
                    />
                    <h6 className="mb-0">
                      {(
                        value * (event.event_metadata?.prices?.[coin] || 1)
                      ).toFixed(2)}
                      $
                    </h6>
                  </div>
                )
            )}
          <div className="d-md-none">
            {(event.event_type === 'pending' || event.performance != null) && (
              <hr className="my-1 mx-3" />
            )}
            {indicators}
          </div>
        </span>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  events: PropTypes.array,
  pendingEvents: PropTypes.array,
  triggerLoadCheckpoint: PropTypes.object,
  setTriggerLoadCheckpoint: PropTypes.func,
  enabled: PropTypes.bool,
  botDetails: PropTypes.object
};

NavItem.propTypes = {
  index: PropTypes.number,
  step: PropTypes.number,
  event: PropTypes.object,
  totalSteps: PropTypes.number,
  goToCheckpoint: PropTypes.func,
  enabled: PropTypes.bool
};

export default WizardLayout;
